import { useCallback, useEffect } from 'react'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { navigate } from 'gatsby'
import { useAuth } from 'src/hooks/auth'
import Section from 'src/components/sections/Section'

function Page() {
  // useEffect(() => {
  //   window.location.href = storeConfig.loginUrl
  // }, [])

  const { checkCookie, user, isValidating } = useAuth()

  const handleIframeLoad = useCallback(
    async (e: React.SyntheticEvent<HTMLIFrameElement>) => {
      if (
        e.currentTarget?.contentWindow?.location.pathname === '/api/redirecting'
      ) {
        try {
          checkCookie()
          navigate('/')
        } catch {
          // eslint-disable-next-line no-alert
          alert('Ocorreu um erro ao logar.')
        }
      }
    },
    [checkCookie]
  )

  useEffect(() => {
    if (!isValidating && user) {
      navigate('/')
    }
  }, [isValidating, user])

  return (
    <>
      <GatsbySeo noindex nofollow />
      <Section marginBottom="0">
        <iframe
          width="100%"
          frameBorder="0"
          title="Login"
          src="/api/login-page?returnUrl=/api/redirecting"
          height={500}
          onLoad={handleIframeLoad}
          // style={{ display: 'none' }}
        />
      </Section>
    </>
  )
}

export default Page
