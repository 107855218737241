import type { PropsWithChildren } from 'react'
import { toClassname } from 'src/utils/toClassname'

import type { SectionProps } from './types'
import * as S from './styles'

function Section({
  children,
  id,
  className = '',
  containerCenter = true,
  marginBottom = '64px',
  marginTop,
  ...rest
}: PropsWithChildren<SectionProps>) {
  return (
    <S.SectionContainer
      id={id ? `section-${id}` : undefined}
      className={toClassname(['section', id, className])}
      containerCenter={containerCenter}
      marginBottom={marginBottom}
      marginTop={marginTop}
      {...rest}
    >
      {children}
    </S.SectionContainer>
  )
}

export default Section
