import type { SectionHeaderProps } from './types'
import * as S from './styles'

const SectionHeader: FCC<SectionHeaderProps> = ({
  children,
  title,
  subTitle,
  side,
}) => {
  return (
    <S.SectionHeaderContainer>
      <S.SectionTitleWrapper>
        <S.SectionTitle>
          {title}
          {subTitle && (
            <>
              {' '}
              <span>{subTitle}</span>
            </>
          )}
        </S.SectionTitle>
        {children}
      </S.SectionTitleWrapper>
      {side}
    </S.SectionHeaderContainer>
  )
}

export default SectionHeader
