import { containerCenter as applyContainerCenter } from 'src/styles/utils/containerCenter'
import { mediaMax } from 'src/styles/utils/mediaMax'
import { setupResponsiveProperty } from 'src/styles/utils/responsiveProperty'
import styled, { css } from 'styled-components'

import type { StyledSectionContainerProps } from './types'

export const SectionContainer = styled.section<StyledSectionContainerProps>`
  position: relative;
  display: flex;
  flex-direction: column;

  ${({ containerCenter }) => containerCenter && applyContainerCenter}

  ${({ marginBottom }) =>
    setupResponsiveProperty('margin-bottom', marginBottom)}

  ${({ marginTop }) => setupResponsiveProperty('margin-top', marginTop)}
`

export const SectionHeaderContainer = styled.header`
  display: flex;
  margin-bottom: 38px;
  width: 100%;

  ${mediaMax(
    css`
      flex-direction: column;
      align-items: center;
      border-bottom: 1px solid ${({ theme }) => theme.colors.orange_1};
      margin-bottom: 16px;
    `
  )}
`

export const SectionTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid #e9554a85;
  padding-bottom: 10px;

  ${mediaMax(
    css`
      border-bottom: 0;
      font-size: 18px;
      line-height: 28px;
      /* padding: 0; */
    `
  )}
`

export const SectionTitle = styled.h2`
  /* display: flex; */
  font-family: 'Baloo';
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 35px;
  color: ${({ theme }) => theme.colors.primary_0};

  /* a,
  span {
    margin-left: 5px;
  } */
  span {
    color: ${({ theme }) => theme.colors.orange_0};
  }
  svg {
    margin-left: 20px;
    width: 35px;
  }
`
