import { css } from 'styled-components'

import { mediaMax } from './mediaMax'

export type ResponsiveProperty =
  | string
  | {
      desktop?: string
      mobile?: string
    }
  | undefined

export const setupResponsiveProperty = (
  propertyName: string,
  responsiveProp: ResponsiveProperty
) =>
  !responsiveProp
    ? ''
    : typeof responsiveProp === 'string'
    ? css`
        ${propertyName}: ${responsiveProp};
      `
    : css`
        ${responsiveProp?.desktop &&
        `${propertyName}: ${responsiveProp.desktop};`}
        ${responsiveProp?.mobile &&
        mediaMax(
          css`
            ${propertyName}: ${responsiveProp.mobile};
          `
        )}
      `
